import React from 'react';
import validator from 'validator'
import { Button, Callout } from "@blueprintjs/core";
import TextInput from '../../components/text-input';
import TextArea from '../../components/text-area';


class ContactForm extends React.Component  {

    constructor(props) {
        super(props);

        this.state= ContactForm.getInitialState();

        this.onChangeInput = this.onChangeInput.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.cleanForm = this.cleanForm.bind(this);
    }

    static getInitialState() {
        return {
            email: '',
            emailErrorText: '',

            firstName: '',
            firstNameErrorText: '',

            lastName: '',

            company: '',
            companyErrorText: '',

            phone: '',

            message: '',
            messageErrorText: '',

            canSubmit: false,
            formSubmittedMessage: ''
        }
    }

    cleanForm() {
        let initialState = ContactForm.getInitialState();
        this.setState(initialState);
    }

    sendEmail() {
        const { t } = this.props;
        let { email, firstName, lastName, company, phone, message, canSubmit} = this.state;
        const name = `${firstName} ${lastName}`;

        // Postmark mail
        const msg = {
                TemplateId: 10231702,
                TemplateModel: {
                    'email': email,
                    'firstName': firstName,
                    'lastName': lastName,
                    'company': company,
                    'phone': phone,
                    'message': message
                },
                To: `Biuwer Team <info@biuwer.com>`,
                From: `Biuwer Team <info@biuwer.com>`,
                ReplyTo: `${name} <${email}>`
        };


        if(canSubmit) {
            this.setState({
                formSubmittedMessage: t('contactPage.form.formSubmittedMessage')
            });
            window.analytics.track('Send Email', msg);

            // Clean messages after 5 sec
            setTimeout(this.cleanForm, 5000);

        }
    }

    onChangeInput(e) {

        this.setState({
            [e.target.name]: e.target.value
        }, this.validateForm);
    }

    validateForm() {
        let { t } = this.props;
        let { email, firstName, company, message } = this.state;

        // TODO: Validate form fields with errorMessages for each field

        // Email
        let emailValid, emailErrorText;
        if(!!email) {
            if(validator.isEmail(email)) {
                emailValid = true;
            } else {
                emailValid = false;
                emailErrorText = t('contactPage.form.emailErrorText')
            }
        } else {
            emailValid = false;
            emailErrorText = '';
        }

        if(emailValid && !!firstName && !!company && !!message) {
            this.setState({
                canSubmit: true
            });
        } else {
            this.setState({
                canSubmit: false,
                emailErrorText: emailErrorText
            });
        }
    }

    render() {
        const { t } = this.props;
        const { email, emailErrorText, firstName, lastName, company, phone, message, canSubmit, formSubmittedMessage } = this.state;


        return [
            <div className="row" key="contactFormRow1">
                <div className="col-12 mb10">
                    <TextInput
                        formStyling="default"
                        required
                        type="text"
                        name="email"
                        labelText={t('contactPage.form.emailLabel').toUpperCase()}
                        placeholder={t('contactPage.form.emailPlaceHolder')}
                        value={email}
                        onChange={this.onChangeInput}
                        errorText={emailErrorText}
                    />
                </div>

            </div>,
            <div className="row" key="contactFormRow2">
                <div className="col-6 mb10">
                    <TextInput
                        formStyling="default"
                        required
                        type="text"
                        name="firstName"
                        labelText={t('contactPage.form.firstNameLabel').toUpperCase()}
                        placeholder={t('contactPage.form.firstNameLabel')}
                        value={firstName}
                        onChange={this.onChangeInput}
                    />
                </div>

                <div className="col-6 mb10">
                    <TextInput
                        formStyling="default"
                        type="text"
                        name="lastName"
                        labelText={t('contactPage.form.lastNameLabel').toUpperCase()}
                        placeholder={t('contactPage.form.lastNameLabel')}
                        value={lastName}
                        onChange={this.onChangeInput}
                    />
                </div>

                <div className="col-6 mb10">
                    <TextInput
                        formStyling="default"
                        required
                        type="text"
                        name="company"
                        labelText={t('contactPage.form.companyLabel').toUpperCase()}
                        placeholder={t('contactPage.form.companyLabel')}
                        value={company}
                        onChange={this.onChangeInput}
                    />
                </div>
                <div className="col-6 mb10">
                    <TextInput
                        formStyling="default"
                        type="text"
                        name="phone"
                        labelText={t('contactPage.form.phoneLabel').toUpperCase()}
                        placeholder={t('contactPage.form.phoneLabel')}
                        value={phone}
                        onChange={this.onChangeInput}
                    />
                </div>
            </div>,
            <div className="row" key="contactFormRow3">
                <div className="col-12 mb10">
                    <TextArea
                        formStyling="default"
                        required
                        name="message"
                        labelText={t('contactPage.form.messageLabel').toUpperCase()}
                        placeholder={t('contactPage.form.messagePlaceHolder')}
                        value={message}
                        onChange={this.onChangeInput}
                    />
                </div>
            </div>,
            <div className="row" key="contactFormRow4">
                {!!formSubmittedMessage ? (
                    <div className="col mb20">
                        <Callout
                            intent="success"
                            title={t('contactPage.form.formSubmittedLabel')}
                        >
                            {formSubmittedMessage}
                        </Callout>

                    </div>
                ) : null}
            </div>,
            <div className="row" key="contactFormRow5">
                <div className="col-12 mb20">
                    <Button
                        type="submit"
                        disabled={!canSubmit}
                        rightIcon="arrow-right"
                        intent="primary"
                        text={t('contactPage.form.submitLabel')}
                        onClick={this.sendEmail}
                    />
                </div>
            </div>

        ];
    }
}


export default ContactForm;