import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import config from '../../../gatsby-config';

// Components
import MainLayout from '../../components/main-layout';
import ContactForm from './contact-form';

class Contact extends Component {
    render() {
        const { t, pageContext } = this.props;

        return (
            <MainLayout
                pageContext={pageContext}
            >
                <div className="container" style={{ paddingTop: config.siteMetadata.navHeaderHeight }}>
                    <h2 className="text-center mv30">{t('contactPage.pageTitle')}</h2>
                    <ContactForm t={t}/>
                </div>
            </MainLayout>
        )

    }
}

export default withTranslation()(Contact);